
// Desktop Sizes
$main-container-height: calc(100vh - 60px);
$banner-height: calc($main-container-height / 5 - 30px);
$dashboard-height: calc($main-container-height * 6 / 7 - 30px);
$max-banner-height: 136px;
$max-dashboard-height: 720px;
$max-height: 900px;

$main-container-width: 1300px;
$sidebar-width: 260px;
$total-dashboard-width: calc($main-container-width - $sidebar-width - 50px);
$student-list-width: 350px;
$dashboard-button-container-width: calc($total-dashboard-width - $student-list-width);

// Colours
$main-blue: #00A1E0;
$main-blue-shaded: #00719e;
$light-blue: #a4dbff;
$background-gray: #F7F7F7;
$main-blue-reduced-opacity: #0ea4e95d;
$error-red: #FF4C4C;
$error-background: #FFECEC;