/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@500&family=Poppins:wght@500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "variables";
@import "normalize";
@import "_deep";

html, body {
  margin:0;
  padding:0;
  overflow-x:hidden;
  letter-spacing: -0.035em;
  height: 100%;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// -------------- TEXT -------------- //
.title-text {
  font-family: 'Poppins';
  font-size: 18px;
  letter-spacing: -0.035em;
}

// -------------- POSITIONING -------------- //
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

/* vertically and horizontally center element -
make sure to use position: relative for the container element! */
.center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-horizontal {
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.relative {
  position: relative;
}

// -------------- CONTAINERS -------------- //
.body {
  height: $dashboard-height;
  max-height: $max-dashboard-height;
}

.white-container {
  background-color: white;
  border-radius: 30px;
  width: 1300px;
  height: $main-container-height;
  max-height: $max-height;
  margin: auto;
}

.button-container {
  padding-top: 12px;
  display: flex;
  justify-content: center;
}

.loading-container {
  height: 300px;
}

// -------------- FORMS AND INPUTS -------------- //
input {
  background-color: #F7F7F7;
  border-radius: 15px;
  padding: 17px;
  padding-left: 25px;
  font-size: 15px;
  box-sizing: border-box;
  border: none;
}

label {
  font-family: "Poppins";
  margin-left: 6px;
  margin-right: 16px;
}

// -------------- BUTTONS -------------- //
button {
  cursor: pointer;
  border: none;
}

.button {
  display: inline-block;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: bold;
  padding: 12px 42px;
  border-radius: 15px;
  cursor: pointer;
  border: none;
}

.button:disabled {
  background-color: lightgray;
  color: white;

  &:hover {
    background-color: lightgray;
    color: white;
    cursor: not-allowed;
  }
}

.button-blue {
  color: white;
  background-color: #00A1E0;
  cursor: pointer;
  border: none;
}

.button-blue:hover {
  color: white;
  background-color: #018fc7;
}

.button-blue:disabled {
  color: white;
  background-color: lightgray;
}

.button-orange {
  color: #F97316;
  background-color: #FFEDD5;
  border-radius: 10px;
  padding: 12px 42px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  border: none;
}

.button-orange:hover {
  background-color: #F97316;
  color: #FFEDD5;
  border-radius: 10px;
  padding: 12px 42px;
}


.button-white {
  color: black;
  background-color: none;
  border: 3px solid #48BEFF;
  cursor: pointer;
  border: none;
}

.button-light-blue {
  background-color: #E0F2FE;
  color: #0EA5E9;
  border-radius: 10px;
  padding: 13px 30px;
  font-weight: 600;
  font-family: 'Poppins';
  cursor: pointer;
  border: none;
}

.button-light-blue:hover {
  background-color: #0EA5E9;
  color: white;
}

.button-light-blue:disabled {
  background-color: #c6c6c6;
  color: white;
  cursor: not-allowed;
}

.button.disabled {
  border: 4px solid lightgray;
  color: lightgray;
}

// -------------- COMPONENTS -------------- //

app-banner {
  height: $banner-height;
  max-height: $max-banner-height;
}

app-dashboard {
  width: $total-dashboard-width;
  height: $dashboard-height;
  max-height: $max-dashboard-height;
}

.student-status {
  font-size: 22px;
  cursor:default;
  
  &.absent {
    background-color: #FEE2E2;
    color: #EF4444;
  }

  &.late {
    background-color: #FFEDD5;
    color: #F97316;
    
  }

  &.excused {
    background-color: #E2E8F0;
    color: #64748B;

  }
}

button.student-status {
  cursor: pointer;

  &.absent:hover, &.absent.selected {
    background-color: #EF4444;
    color: #FFEDD5;
  }

  &.late:hover, &.late.selected {
    background-color: #F97316;
    color: #FFEDD5;
  }

  &.excused:hover, &.excused.selected {
    background-color: #64748B;
    color: #E2E8F0;
  }
}

.error-banner {
  color: rgb(220, 31, 31);
  font-weight: 600;
  font-family: 'Lato';
  background-color: rgb(255, 218, 218);
  padding: 8px 12px;
  text-align: center;
  border-radius: 8px;
}

.success-banner {
  color: white;
  font-weight: 600;
  font-family: 'Lato';
  background-color: rgb(9 199 14);
  padding: 8px 12px;
  text-align: center;
  border-radius: 8px;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

}

// -------------- SCROLLING -------------- //
.scroll::-webkit-scrollbar {
  width: 0.5em;
}

.scroll::-webkit-scrollbar-track {
    background: rgba(0,0,0, 0);
}

.scroll::-webkit-scrollbar-thumb {
    background: $light-blue;
    border-radius: 14px;
    border: solid 1px rgba(0, 0, 0, 0);
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}



