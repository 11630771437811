mat-dialog-container {
    border-radius: 25px !important;
  }
  
  .mat-menu-content:not(:empty) {
    padding: 0px !important;
  }
  
  .mat-menu-panel {
    border-radius: 12px !important;
  }
 
  
  .mat-tooltip {
    font-size: 11px;
  }
  
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #00A1E0; 
  }